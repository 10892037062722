import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import Root from './../components/common/Root'
import Header from './../components/common/Header'
import PressReleaseHero from './../components/page-components/PressReleaseHero'

export default function Home({ location }) {
  const [emailSubmitted, setEmailSubmitted] = useState(location.search == '?thanks' ? true : false);
  return (
    <Root>
      <Helmet>
        <title>Bitcoin Alliance</title>
        <meta name="description" content="Bitcoin Alliance" />
        <script type="text/javascript" src="https://code.createjs.com/1.0.0/createjs.min.js" />
        <script type="text/javascript" src="/hero-animation/main.js" />
        <script type="text/javascript" src="/hero-animation/initAnimation.js" />
        
      </Helmet>
      <Header
        hero={
          <PressReleaseHero/>
        }
      />
    </Root>
  )
}
